/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Anchor, Box, Image } from "grommet"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import emailButton from "../images/email1.gif"

const Bio = ({ extended = false }) => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
            email
          }
          social {
            linkedin
            github
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social
  console.log({ social })

  return (
    <div className="bio">
      <StaticImage
        className="bio-avatar"
        placeholder="blurred"
        layout="fixed"
        formats={["AUTO", "WEBP", "AVIF"]}
        src="../images/pb-and-sb.png"
        loading="eager"
        width={100}
        height={100}
        quality={65}
        alt="Profile picture"
      />
      <div>
        {author?.name && (
          <>
            <p>
              Written by <strong>{author.name}</strong>{" "}
              {author?.summary || null}
            </p>
            {extended && (
              <p style={{ marginTop: "2em" }}>
                My favorite technologies these days are React, Node.js,
                Typescript, and PostgreSQL.{" "}
              </p>
            )}
          </>
        )}
        {extended && (
          <div style={{ margin: "2em 0" }}>
            <p>Find me online:</p>
            <ul>
              <li>
                <a
                  target="_blank"
                  href={`https://linkedin.com/in/${social.linkedin}`}
                  rel="noreferrer"
                >
                  linkedin
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href={`https://github.com/${social.github}`}
                  rel="noreferrer"
                >
                  github
                </a>
              </li>
            </ul>
            <Box direction="row-responsive" gap="xlarge" align="end">
              <Anchor href={`mailto:${author.email}`}>
                <Image src={emailButton} alt="email button" />
              </Anchor>
              {/* <Button
                height={"40px"}
                css={{ display: "flex" }}
                align="end"
                justify="end"
                onClick={() => {
                  navigate("/guestbook")
                }}
              > */}
              {/* <StaticImage
                  src="../images/guestbook.png"
                  alt="GuestBook Link"
                /> */}
              {/* </Button> */}
            </Box>
          </div>
        )}
      </div>
    </div>
  )
}

export default Bio
